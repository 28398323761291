/*******************************
         Site Overrides
*******************************/

.ui.primary.button:hover {
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.3) inset,
    0px 2px 3px 0px rgba(0, 0, 0, 0.35) !important
  ;
}

.ui.secondary.button:hover {
  box-shadow:
    0px 0px 0px 1px rgba(0, 0, 0, 0.2) inset,
    0px 2px 3px 0px rgba(0, 0, 0, 0.3) !important
  ;
}
